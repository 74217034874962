// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/TextLink.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/TextLink.tsx");
  import.meta.hot.lastModified = "1709804301424.4265";
}
// REMIX HMR END

import { Link } from "@remix-run/react";
import { NamedIcon } from "./NamedIcon";
import { cn } from "~/utils/cn";
const variations = {
  primary: "text-indigo-500 transition hover:text-indigo-400 inline-flex gap-0.5 items-center group",
  secondary: "text-text-dimmed transition underline underline-offset-2 decoration-dimmed/50 hover:decoration-dimmed inline-flex gap-0.5 items-center group"
};
export function TextLink({
  href,
  to,
  children,
  className,
  trailingIcon,
  trailingIconClassName,
  variant = "primary",
  ...props
}) {
  const classes = variations[variant];
  return to ? <Link to={to} className={cn(classes, className)} {...props}>
      {children}{" "}
      {trailingIcon && <NamedIcon name={trailingIcon} className={cn("h-4 w-4", trailingIconClassName)} />}
    </Link> : href ? <a href={href} className={cn(classes, className)} {...props}>
      {children}{" "}
      {trailingIcon && <NamedIcon name={trailingIcon} className={cn("h-4 w-4", trailingIconClassName)} />}
    </a> : <span>Need to define a path or href</span>;
}
_c = TextLink;
var _c;
$RefreshReg$(_c, "TextLink");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;